import 'firebase/firestore';
import { auth } from '../../firebase';
import { useSelector, useDispatch } from 'react-redux';
import { setUser, initialState } from 'store/auth/userSlice';
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice';
import appConfig from 'configs/app.config';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, gql, useLazyQuery } from '@apollo/client';
import { useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import authRoute from 'configs/routes.config/authRoute';

function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { token, signedIn } = useSelector((state) => state.auth.session);

  const queryParams = new URLSearchParams(window.location.search);
  const redirectUrl = queryParams.get('redirectUrl');
  const doctor_id = queryParams.get('doctor_id');
  const specialty = queryParams.get('specialty');
  const date = queryParams.get('date');
  const type = queryParams.get('type');
  const appointmentUrl = `${redirectUrl}&specialty=${specialty}&date=${date}&type=${type}`;

  const authUrl = redirectUrl
    ? redirectUrl.includes('/checkout')
      ? appointmentUrl
      : redirectUrl
    : appConfig.authenticatedEntryPath;

  const [apiSignIn] = useMutation(SIGN_IN);
  const [apiSignUp] = useMutation(SIGN_UP);
  const [apiForgotPassword] = useMutation(FORGOT_PASSOWRD);
  const { data, loading, error } = useQuery(GET_USER, {
    skip: !localStorage.getItem('token'),
    variables: {
      email: localStorage.getItem('email'),
    },
  });
  const [createDoctor] = useMutation(CREATE_DOCTOR);
  const [createPacient] = useMutation(CREATE_PACIENT);
  const [getUser] = useLazyQuery(GET_USER);

  const configUser = useCallback((user) => {
    dispatch(
      setUser({
        ...user,
        avatar: '',
        authority: user.role || ['user'],
        userName: user.firstname + ' ' + user.lastname,
        email: user.email,
      })
    );

    const token = localStorage.getItem('token');
    dispatch(onSignInSuccess(token));

    const checkoutUrl = localStorage.getItem('checkoutUrl');
    if (checkoutUrl) {
      navigate(checkoutUrl);
      localStorage.removeItem('checkoutUrl');
    }
  }, []);

  useEffect(() => {
    if (data?.users?.length > 0) {
      const user = data.users[0];
      configUser(user);
    }
  }, [data]);

  useEffect(() => {
    if (
      token &&
      signedIn &&
      pathname !== '/' &&
      authRoute.find((route) => route.path.includes(pathname))
    ) {
      navigate(authUrl);
    }
  }, [pathname, token, signedIn]);

  const fetchUserInformation = async (email) => {
    return fetch('https://api.drderma.com.br/v1/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-hasura-admin-secret':
          'SBxfxz9zoW9zIDwXmS470bDKp7ml80PUbsvI45xRC1kh0T956Jwf41aQEOAEOKSF',
      },
      body: JSON.stringify({
        query: `query getUser($email: String!) {
          users(where: { email: { _eq: $email } }) {
            firebase_uid
            id
            email
            firstname
            lastname
            document
            role
          }
        }`,
        variables: {
          email,
        },
      }),
    })
      .then((res) => res.json())
      .catch((err) => console.error(err));
  };

  const signIn = async (values, { setMessage }) => {
    try {
      const resp = await apiSignIn({
        variables: {
          input: { ...values, email: values?.email?.toLowerCase() },
        },
      });
      if (resp?.data?.signIn) {
        const { access_token: token } = resp.data.signIn;
        localStorage.setItem('token', token);
        localStorage.setItem('email', values.email?.toLowerCase());

        const userResponse = await fetchUserInformation(values.email?.toLowerCase());
        if (userResponse?.data?.users?.length > 0) {
          const user = userResponse?.data?.users[0];
          configUser(user);
        }
      }
    } catch (errors) {
      console.error(errors);
      setMessage('Erro ao entrar, verifique os campos e tente novamente.');
    }
  };

  const signUp = async ({ type, ...values }, { setMessage }) => {
    try {
      const resp = await apiSignUp({
        variables: {
          input: {
            ...values,
            email: values?.email?.toLowerCase(),
            country: 'BR',
            role: type === 'doctor' ? 'doctor' : 'user',
          },
        },
      });
      if (resp?.data?.signUp) {
        const { access_token: token } = resp?.data?.signUp;
        localStorage.setItem('token', token);
        localStorage.setItem('email', values.email?.toLowerCase());
        if (type) {
          localStorage.removeItem('user_register_type');
        }

        const userResponse = await fetchUserInformation(values.email?.toLowerCase());
        if (userResponse?.data?.users?.length > 0) {
          const user = userResponse?.data?.users[0];
          configUser(user);
        }
      }
    } catch (errors) {
      setMessage('Erro ao cadastrar usuário, verifique os campos e tente novamente.');
    }
  };

  const forgotPassword = async (values, { setMessage }) => {
    try {
      const res = await apiForgotPassword({
        variables: {
          input: values,
        },
      });
      return res;
    } catch (errors) {
      setMessage('Erro ao enviar email, verifique os campos e tente novamente.');
    }
  };

  const signOut = async () => {
    await auth?.signOut();
    localStorage.removeItem('token');
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.tourPath);
    window.location.reload();
  };

  useEffect(() => {
    if (error) {
      if (error?.message?.includes('Could not verify JWT: JWTExpired')) {
        signOut();
      }
    }
  }, [error]);

  return {
    loading,
    authenticated: token && signedIn,
    signIn,
    signUp,
    forgotPassword,
    signOut,
  };
}

export default useAuth;

export const SIGN_IN = gql`
  mutation signIn($input: SignInInput!) {
    signIn(input: $input) {
      access_token
    }
  }
`;

export const SIGN_UP = gql`
  mutation signUp($input: SignUpInput!) {
    signUp(input: $input) {
      access_token
    }
  }
`;

export const FORGOT_PASSOWRD = gql`
  mutation forgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      message
      success
    }
  }
`;

export const GET_USER = gql`
  query getUser($email: String!) {
    users(where: { email: { _eq: $email } }) {
      firebase_uid
      id
      email
      firstname
      lastname
      document
      gender
      date_of_birth
      zipcode
      address
      number
      complement
      country
      city
      region
      role
      image_url
      telephone
    }
  }
`;

export const CREATE_DOCTOR = gql`
  mutation createDoctor($input: [doctors_insert_input!]!) {
    insert_doctors(objects: $input) {
      affected_rows
    }
  }
`;

export const CREATE_PACIENT = gql`
  mutation createPacient($input: [pacients_insert_input!]!) {
    insert_pacients(objects: $input) {
      affected_rows
    }
  }
`;
